.classic-hw-card {
  text-align: 'center';
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 2rem 3rem;
  border-bottom: 3px solid $tertiary-light;
}

.hwCard {
  max-width: 345px;
  background: #ffffff;
  box-shadow: 0px 1px 0px #ebebeb, -12px 24px 21px rgba(0, 0, 0, 0.03),
    12px 24px 21px rgba(0, 0, 0, 0.03) !important;
  border-radius: 8px !important;
  // margin: 0 1rem 1rem;
  .media {
    height: 235px;
  }
  .content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 10px;
    justify-content: space-between;
    h2 {
      font-family: 'Merriweather';
      font-weight: 500;
      margin: 10px 0 0 0;
      color: $primary-dark;
    }
    h3 {
      line-height: 175%;
      margin-top: 0;
      letter-spacing: 0.15px;
      color: $primary-dark;
    }
    h4 {
      margin-top: 0;
      color: $primary-text;
    }
    h5 {
      margin: 0;
      color: $primary-text;
    }
  }
}

.interest-form {
  .disclaimer {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    font-size: 0.9rem;
    line-height: 1.2rem;
    color: $secondary-text;
  }
}

@media only screen and (max-width: 900px) {
  .classic-hw-card {
    padding: 2rem 1rem;
  }
}
