.reviewSlider {
  position: relative;
  overflow-x: hidden;
  padding: 1.5rem 0rem 0rem;
  .slick-list {
    padding-bottom: 30px !important;
  }
  .slick-track {
    display: flex;
    .slick-slide {
      height: auto;
      > div {
        height: 100%;
        > div {
          height: 100%;
          > div {
            height: 100%;
          }
        }
      }
    }
  }
}
.slick-prev {
  font-size: 15px !important;
  &:before {
    content: '' !important;
  }
}
.slick-next {
  font-size: 15px !important;
  &:before {
    content: '' !important;
  }
}
.slick-initialized {
  .slick-slide.slick-active {
    z-index: 1;
  }
}
.hwDots {
  position: absolute;
  bottom: 0px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  li {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
    button {
      font-size: 0;
      line-height: 0;
      display: block;
      width: 20px;
      height: 20px;
      padding: 5px;
      cursor: pointer;
      color: transparent;
      border: 0;
      outline: none;
      background: transparent;
      &:before {
        font-family: 'Font Awesome 5 Free';
        font-size: 10px;
        line-height: 20px;
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        font-weight: 400;
        content: '\f111';
        text-align: center;
        color: $primary-dark;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }
  }
  .slick-active {
    button {
      &:before {
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        content: '\f111';
        color: #46d7f0;
      }
    }
  }
}

.listingNext {
  z-index: 10;
  position: absolute !important;
  width: 3rem;
  height: 3rem;
  right: 0rem !important;
  margin-top: -1.5rem !important;
  top: 50%;
  transform: translate(0, -50%);
}
.listingPrev {
  z-index: 2;
  position: absolute !important;
  width: 3rem;
  height: 3rem;
  left: 0rem !important;
  margin-top: -1.5rem !important;
  top: 50%;
  transform: translate(0, -50%);
}
@media only screen and (max-width: 960px) {
  .listingNext {
    right: 1rem !important;
    top: 30%;
    transform: translate(0, -30%);
  }
  .listingPrev {
    left: 1rem !important;
    top: 30%;
    transform: translate(0, -30%);
  }
}
