.navBtn {
  display: block !important;
  margin-top: 0 !important;
  padding: 8px 12px 6px !important;
  text-transform: none !important;
  font-weight: 400 !important;
  font-size: 15px !important;
  color: #ffffff !important;
}
.secondaryBtn {
  margin-top: 0 !important;
  // margin-left: 2rem !important;
  background: #ffffff !important;
  border: 1px solid rgba(50, 104, 34, 0.5) !important;
  // box-shadow: 0px 5px 5px rgba(50, 104, 34, 0.1) !important;
  box-shadow: none !important;
  border-radius: 8px !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 15px;
  line-height: 18px;
  text-transform: none;
  color: #4c6141 !important;
  display: block;
  transition: all 0.2s ease-in-out !important;
  &:hover {
    transform: translateY(-1px) !important;
    background: #eaf5e4 !important;
    // box-shadow: none !important;
    box-shadow: 0px 5px 5px rgba(50, 104, 34, 0.1) !important;
  }
}
.primaryBtn {
  margin-top: 0 !important;
  padding: 5px 12px !important;
  // margin-left: 2rem !important;
  background: #ffffff !important;
  border: 1px solid rgba(20, 123, 201, 0.5) !important;
  // box-shadow: 0px 5px 5px rgba(49, 121, 198, 0.15) !important;
  box-shadow: none !important;
  border-radius: 8px !important;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 15px;
  line-height: 18px;
  text-transform: none;
  color: #686868 !important;
  display: block;
  transition: all 0.2s ease-in-out !important;
  &:hover {
    transform: translateY(-1px) !important;
    background: #f3f9fd !important;
    // box-shadow: none !important;
    box-shadow: 0px 5px 5px rgba(49, 121, 198, 0.15) !important;
  }
}
.primaryBtnActive {
  margin-top: 0 !important;
  background: #87c1ee !important;
  border: 1px solid rgba(20, 123, 201, 0.5) !important;
  box-shadow: none !important;
  border-radius: 8px !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 15px;
  line-height: 18px;
  text-transform: none;
  color: $primary-text !important;
  display: block;
  transition: all 0.2s ease-in-out !important;
  &:hover {
    transform: translateY(-1px) !important;
    background: #87c1ee !important;
    box-shadow: 0px 5px 5px rgba(49, 121, 198, 0.15) !important;
  }
}
.bedsBtn {
  margin-top: 0 !important;
  margin-right: 12px !important;
  background: #ffffff !important;
  border: 1px solid rgba(20, 123, 201, 0.5) !important;
  box-shadow: 0px 5px 5px rgba(49, 121, 198, 0.15) !important;
  border-radius: 8px !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 15px;
  line-height: 18px;
  text-transform: none;
  color: #3179c6 !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  min-width: 45px !important;
  display: block;
  transition: all 0.2s ease-in-out !important;

  &:hover {
    transform: translateY(1px) !important;
    background: #ffffff !important;
    box-shadow: none !important;
  }

  &-active {
    @extend .bedsBtn;
    background: #3179c6 !important;
    // font color is white
    color: #ffffff !important;
    &:hover {
      transform: translateY(1px) !important;
      background: #3179c6 !important;
      box-shadow: none !important;
    }
  }
}

.btnStack {
  margin: 0 0 1rem;
  justify-content: space-around;

  .notButton {
    font-size: 1.5rem;
    font-weight: 400;
    flex-direction: row;
  }

  .icon {
    color: #147bc9;
    width: 35px;
    height: 35px;
  }
}

@media only screen and (max-width: 599px) {
  .btnStack {
    .notButton {
      font-size: 1rem;
      flex-direction: column;
    }
    .icon {
      width: 25px;
      height: 25px;
    }
  }
  .primaryBtn {
    padding: 8px 16px 8px !important;
  }
}
