.custom-marker-icon {
  text-align: center;
  color: white;
  /* font-weight: bold; */
}

.custom-marker-icon__label {
  position: absolute;
  top: 150%;
  left: 145%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}

.custom-marker-icon__detailed-label {
  position: absolute;
  top: -65%;
  left: 45%;
  transform: translate(-48%, -60%);
  pointer-events: none;
  font-size: 12px;
}

.map-container {
  position: relative;
}

.map-container .loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(3px); /* Adjust the blur effect as needed */
  background-color: rgba(
    255,
    255,
    255,
    0.2
  ); /* Adjust the background color and opacity as needed */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.map-container .loading-overlay .loading-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.map-container .loading-overlay .loading-dots span {
  display: inline-block;
  margin: 0 4px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #054285; /* Adjust the color of the loading dots as needed */
  animation: dot-fading 1s infinite;
}

@keyframes dot-fading {
  0%,
  80%,
  100% {
    opacity: 0.2;
  }
  40% {
    opacity: 1;
  }
}
