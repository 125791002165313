$primary-color: #054d85;
$secondary-color: #147cb9;
$box-shadow-primary: 0px 1px 0px $primary-color,
  0px 5px 5px rgba(49, 121, 198, 0.15), -12px 24px 21px rgba(0, 0, 0, 0.05),
  12px 24px 21px rgba(0, 0, 0, 0.05);
$box-shadow-secondary: 0px 1px 0px $primary-color,
  rgba(20, 123, 201, 0.4) 0px 8px 25px 0px;

.root {
  display: flex;
  flex-direction: column;
}

.reviewSlider2 {
  margin-top: -5px;
  padding: 1rem 0rem 1rem;
  position: inherit;
  overflow-x: hidden;

  .slick-list {
    padding-bottom: 30px !important;
  }

  .slick-track {
    display: flex;

    .slick-slide {
      height: auto;
      margin: 0 0.5rem;

      > div {
        height: 100%;

        > div {
          width: calc(100% - 1px) !important;
          height: 100%;

          > div {
            height: 100%;
          }
        }
      }
    }
  }
}

.reviewNext,
.reviewPrev {
  z-index: 10;
  position: absolute !important;
  width: 3rem;
  height: 3rem;
  margin-top: -1.5rem !important;
  top: 50%;
  transform: translate(0, -50%);
}

.reviewPrev {
  z-index: 2;
  left: -3rem !important;
}
.reviewNext {
  z-index: 2;
  right: -3rem !important;
}

@media only screen and (max-width: 960px) {
  .reviewNext,
  .reviewPrev {
    right: 1rem !important;
    top: 30%;
    transform: translate(0, -30%);
  }

  .reviewPrev {
    left: 1rem !important;
  }
}

.hw_btn_primary_main {
  width: fit-content;
  border-bottom: 1px solid $primary-color !important;
  box-shadow: $box-shadow-secondary !important;
}

.hw_btn_primary,
.hw_btn_secondary {
  margin-bottom: 45px !important;
  border-bottom: 1px solid $primary-color !important;
  box-shadow: $box-shadow-primary !important;
}

.hw_btn_secondary {
  margin: 0 1rem 45px !important;
  border: 2px solid $secondary-color !important;
  border-radius: 10px !important;
  background: #fff !important;
  color: $secondary-color !important;
  text-transform: none !important;
}

@media only screen and (max-width: 768px) {
  .hw_btn_primary_main {
    width: auto;
  }
  .hw_btn_secondary {
    margin: 0 0 45px !important;
  }
}

.section {
  padding: 2rem 2rem 3rem;

  @media only screen and (max-width: 960px) {
    padding: 1rem 0.5rem 2rem;
    text-align: center;
  }
}

.hero {
  display: block;
  background: #fff;
  background-size: 100% auto;

  @media only screen and (max-width: 960px) {
    background-size: auto 100%;
  }
}

.heroCard {
  background-color: rgba(255, 255, 255, 0.97) !important;
  padding: 1.5rem 2rem 2.5rem;

  @media only screen and (max-width: 960px) {
    padding: 1rem 1rem 1.5rem;
  }
}

.heroNoCard {
  padding: 1rem 2rem 2rem;
  text-align: center;

  @media only screen and (max-width: 960px) {
    padding: 1rem 0.5rem 2rem;
  }
}

.logo {
  margin: 1rem;

  @media only screen and (max-width: 960px) {
    width: 100%;
    max-width: 300px;
    margin: 1rem auto;
  }
}

.formCard {
  text-align: center;
  background: rgb(255 255 255 / 100%);
  padding: 1rem 2rem 2rem;
  border-bottom: 5px solid #ccefc4;
  margin-bottom: -9rem;
}

.fullWidth {
  width: 100%;
  display: block;
  margin: 0 auto;
}

.rounded {
  border-radius: 12px;
  margin: 45px 12px;
  filter: drop-shadow(0px 1px 0px #ebebeb)
    drop-shadow(-12px 24px 21px rgba(0, 0, 0, 0.03))
    drop-shadow(12px 24px 21px rgba(0, 0, 0, 0.03));

  @media only screen and (max-width: 960px) {
    border-radius: 12px;
    margin: 24px 2px;
    filter: drop-shadow(0px 1px 0px #ebebeb)
      drop-shadow(-2px 12px 12px rgba(0, 0, 0, 0.03))
      drop-shadow(2px 12px 12px rgba(0, 0, 0, 0.03));
  }
}

.textAlign {
  text-align: center;

  @media only screen and (max-width: 960px) {
    text-align: left;
  }
}

.angleSm {
  position: absolute;
  bottom: -1px;
  width: 100%;
  height: 10%;
}

.getStarted {
  display: block;
  background-color: rgba(243, 249, 253, 1);
}

.finalCta {
  padding-top: 3rem;
  padding-bottom: 0;
  margin-bottom: 0;
  background: #f3f9fd;
}

.boostSize {
  font-size: 2.45rem;

  @media only screen and (max-width: 960px) {
    font-size: 2rem;
  }
}

.realtorCard {
  display: flex;
  flex-direction: column !important;
  height: calc(100% - 100px);
  justify-content: space-between;

  @media only screen and (max-width: 960px) {
    height: auto;
    display: block;
  }
}

.imageAlign {
  text-align: right;

  @media only screen and (max-width: 960px) {
    text-align: center;
  }
}

.rating {
  display: flex;
  margin-top: 3rem;
  @media only screen and (max-width: 960px) {
    justify-content: center;
    font-size: 1rem;
  }
}
