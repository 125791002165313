.main-details {
  display: flex;
  justify-content: space-between;
  h2 {
    margin-top: 0.25rem;
  }
  h3 {
    margin-top: 0.25rem;
    color: $primary-text;
    .secondary {
      color: $secondary-text;
    }
  }
  .stacks {
    text-align: right;
    padding-top: 0.75rem;
  }
}

.sticky-sidebar {
  position: sticky;
  top: 138px;
}

.suggestion-header {
  font-weight: 700;
  padding: 1rem 0rem 1rem 1rem;
  color: $primary-text;
}
.suggestion-list {
  padding: 0 0 1rem;
  margin: 0;
  li {
    padding: 0 1rem 0.8rem;
    margin: 0;
    list-style: none;
    a {
      color: $primary-text;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: block;
      transition: all 0.2s ease-in-out !important;
      &:hover {
        color: $primary-dark;
        text-decoration: underline !important;
        transform: translateY(1px) !important;
      }
    }
  }
}

.img-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  max-height: 540px;
  position: relative;

  // grid-area: [row-start] / [column-start] / [row-end] / [column-end];
  .div1 {
    grid-row: 1 / span 2;
    grid-column: 1 / span 2;
    background-color: rgba(0, 123, 255, 0.25);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    border: 1px solid #ffffff;
    border-top: 2px;
    border-bottom: 2px;
    border-left: 0px;
    transition: 0.3s ease-in-out;
    filter: saturate(1);
    &:hover {
      cursor: pointer;
      opacity: 0.85;
    }
  }
  .div2 {
    grid-column: 3 / span 1;
    grid-row: 1 / span 1;
    background-color: rgba(50, 104, 34, 0.25);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    border: 1px solid #ffffff;
    border-top: 2px;
    transition: 0.3s ease-in-out;
    filter: saturate(1);
    &:hover {
      cursor: pointer;
      opacity: 0.85;
    }
  }
  .div3 {
    grid-column: 4 / span 1;
    grid-row: 1 / span 1;
    background-color: rgba(0, 123, 255, 0.225);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    border: 1px solid #ffffff;
    border-top: 2px;
    border-right: 0px;
    transition: 0.3s ease-in-out;
    filter: saturate(1);
    &:hover {
      cursor: pointer;
      opacity: 0.85;
    }
  }
  .div4 {
    grid-column: 3 / span 1;
    grid-row: 2 / span 1;
    background-color: rgba(0, 123, 255, 0.125);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    border: 1px solid #ffffff;
    border-bottom: 2px;
    transition: 0.3s ease-in-out;
    filter: saturate(1);
    &:hover {
      cursor: pointer;
      opacity: 0.85;
    }
  }
  .div5 {
    grid-column: 4 / span 1;
    grid-row: 2 / span 1;
    background-color: rgba(50, 104, 34, 0.225);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    border: 1px solid #ffffff;
    border-bottom: 2px;
    border-right: 0px;
    transition: 0.3s ease-in-out;
    filter: saturate(1);
    &:hover {
      cursor: pointer;
      opacity: 0.85;
    }
  }
}

.listing-details {
  h3 {
    /* H3 Medium */
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    letter-spacing: -0.01em;
    color: #333333;
    margin-bottom: 0px;
  }
  p {
    font-size: 0.9rem;
    margin-top: 5px;
    margin-bottom: 0px;
  }
  .secondary {
    color: $secondary-text;
  }
}

.similar-listings {
  padding-bottom: 5rem;
  h3 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    letter-spacing: -0.01em;
    color: #333333;
    margin-bottom: 0px;
  }
  p {
    font-size: 0.9rem;
    margin-top: 5px;
    margin-bottom: 0px;
  }
}

.forSaleCalc {
  h3 {
    margin: 0px 0px 1rem;
  }
  h4 {
    margin: 0px 0px 0.5rem;
  }
  a:hover {
    background-color: #ccefc4;
    color: #054d85;
  }
}

@media only screen and (max-width: 900px) {
  .main-details {
    display: flex;
    flex-direction: column;
    // text-align: center;
    .stacks {
      text-align: left;
    }
  }
  .img-grid {
    display: block;
    max-height: 245px;
    position: relative;
  }
}
