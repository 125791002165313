$primary-text: #4c5257;
$secondary-text: #707070;

$primary-light: #007bff;
$primary-main: #3179c6;
$primary-dark: #054d85;
$primary-contrastText: #ffffff;

$secondary-light: #007bff;
$secondary-main: #3179c6;
$secondary-dark: #054d85;
$secondary-contrastText: #3179c6;

$tertiary-light: #ccefc4;
$tertiary-main: #326822;
$tertiary-dark: #264f1a;
$tertiary-contrastText: #ffffff;
