// #map1 {
//   height: 100%;
//   margin: 0;
// }

.mapSection {
  height: calc(100vh - 128px);
}
.mapSection__content {
  position: relative;
  display: flex;
  height: calc(100% - 128px);
  pointer-events: none;
  & > * {
    pointer-events: auto;
  }
}
.footer {
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $primary-main;
  color: #ffffff;
  padding: 0 20px;
  p {
    margin: 0px;
    text-align: center;
  }
  ul {
    list-style-type: none;
    li {
      display: inline;
      padding-left: 1rem;
      a {
        text-decoration: none;
        color: white;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

#googleMap {
  position: absolute;
  height: calc(100% - 128px);
  top: 128px;
  left: 0;
  background-color: #e8e8e8;
}

.mapOverlay {
  position: absolute;
  z-index: 1;
  top: calc(50% - 175px);
  left: calc(50% - 285px);
  min-width: 500px;
  max-height: 170px;
  display: flex;
  flex-direction: column;
  padding: 10px 10px 0px 10px;
  box-shadow: 0px 1px 0px #ebebeb, -12px 24px 21px rgba(0, 0, 0, 0.03),
    12px 24px 21px rgba(0, 0, 0, 0.03);
  border-radius: 12px;
  overflow: visible !important;
  .media {
    width: 220px;
    height: 160px;
    border-radius: 6px;
  }
  .content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    padding: 0 0 0 10px !important;
    justify-content: space-between;
    h2 {
      font-family: 'Merriweather';
      font-weight: 500;
      margin: 0;
      color: $primary-dark;
    }
    h4 {
      line-height: 175%;
      margin-top: 0;
      letter-spacing: 0.15px;
      text-transform: uppercase;
      color: $primary-main;
    }
    h5 {
      margin: 0 0 10px 0;
      color: $primary-text;
    }
  }
  .pinBottom {
    position: absolute;
    bottom: -14px;
    left: calc(50% - 15px);
    width: 30px;
    height: 15px;
  }
}

@media only screen and (max-width: 900px) {
  .mapSection {
    height: calc(100vh - 112px);
  }
  .mapSection__content {
    height: calc(100% - 112px);
  }
  #googleMap {
    height: calc(100% - 112px);
    top: 112px;
    left: 0;
    background-color: #e8e8e8;
  }
  .mapOverlay {
    min-width: calc(100% - 20px);
    border-radius: 12px 12px 0 0 !important;
    top: auto;
    bottom: 0px;
    left: 0px;
    // scroll lock for mobile
    overflow: hidden !important;
    .media {
      width: 100%;
      height: 160px;
      border-radius: 6px;
    }
    .content {
      padding: 0 0 0 10px !important;
      h2 {
        font-size: 18px;
      }
      h4 {
        font-size: 14px;
      }
      h5 {
        font-size: 12px;
      }
    }
    .pinBottom {
      display: none;
    }
  }
}
