@import './styles/vars.scss';

@import './styles/cards.scss';
@import './styles/buttons.scss';
@import './styles/details.scss';
@import './styles/map.scss';
@import './styles/sliders.scss';
@import './styles/pages.scss';

body {
  margin: 0;
  font-family: 'Inter', arial, sans-serif;
  color: $primary-text;
  background-color: #f3f9fd;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
p {
  line-height: 1.5rem;
}
h1 {
  color: $primary-dark;
  font-weight: 500;
  margin-bottom: 0;
  margin-top: 0;
  font-size: 2rem;
}
h2,
h3,
h4,
h5,
h6 {
  color: $primary-dark;
  font-weight: 500;
  margin-bottom: 0;
}
a {
  text-decoration: none;
}
.link {
  color: $primary-main;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}
.error {
  color: #ea3546;
}
.rounded10 {
  border-radius: 10px !important;
  text-decoration: none !important;
}
.sm-font {
  font-family: 'Roboto', sans-serif;
  font-size: 0.55em;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  font-weight: 700;
}
.highlight {
  position: relative;
  display: inline-block;
  z-index: 1;
  // font-weight: 700;
  &:after {
    content: '';
    left: -1.25%;
    position: absolute;
    width: 102.5%;
    height: 35%;
    top: 50%;
    -webkit-transform: translateY(-36%);
    transform: translateY(-36%);
    background-color: #ccefc4;
    z-index: -2;
  }
}
.subtitle {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 175%;
  margin-top: 0;
  letter-spacing: 0.15px;
  text-transform: uppercase;
  color: $primary-dark;
}
.sub-text {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  margin-top: 0;
  letter-spacing: 0.15px;
  color: $secondary-text;
}
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.toolBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.toolBar2 {
  display: flex;
  flex-direction: row;
}
.secondaryAppBar {
  top: 64px !important;
  padding: 0px;
  // box-shadow: none !important;
  background: #ffffff !important;
  height: 54px !important;
}

.search-box {
  width: 100%;
  background: #ffffff !important;
  border: 1px solid rgba(20, 123, 201, 0.5) !important;
  box-shadow: 0px 5px 5px rgba(49, 121, 198, 0.15) !important;
  border-radius: 8px !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 15px;
  line-height: 18px;
  text-transform: none;
  color: #3179c6 !important;
  input {
    padding-bottom: 0px !important;
  }
}

.suggestion-item,
.suggestion-item--active {
  text-align: left !important;
  padding: 0 0 0 10px;
  margin: 5px 0px;
  color: #000;
  font-family: Merriweather Sans, 'Helvetica Neue', Arial, sans-serif;
  &:hover,
  &:focus {
    color: #000;
    background-color: #ccefc4;
  }
}

.suggestion-item--active {
  /* background-color: rgba(0, 0, 0, 0.04) !important; */
  color: #000;
  background-color: #ccefc4;
}

.autocomplete-dropdown-container {
  position: absolute;
  font-size: 1rem;
  border-radius: 5px;
  width: 400px;
  top: 50px;
  left: -10px;
  border: 0px solid grey;
  background-color: #fff;
  z-index: 9999;
  box-sizing: border-box;
  box-shadow: 0px 0px 5px #ccc;
  cursor: pointer;
}

.filterDrawer {
  padding: 16px;
  h2 {
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    letter-spacing: -0.01em;
    margin-bottom: 10px;
  }
}

.statusGroup,
.saleLeaseGroup {
  background: #fff;
  button {
    margin-top: 0px !important;
    padding: 5px 12px !important;
  }
  // padding-right: 16px;
}

.saleLeaseGroup {
  padding-left: 0px;
  padding-right: 16px;
}

.daysActiveGroup {
  background: #fff;

  button {
    margin-top: 0px !important;
    padding: 5px 12px !important;
  }
  padding-left: 0px;
}

.divider {
  color: rgba(51, 51, 51, 0.54);
  display: flex;

  &:before,
  &:after {
    content: '';
    flex: 1;
  }
}
.line {
  align-items: center;
  margin: 1em -1em;

  &:before,
  &:after {
    height: 1px;
    margin: 0 1em;
  }
}
.one-line {
  &:before,
  &:after {
    background: rgba(51, 51, 51, 0.54);
  }
}

.ftr {
  background-color: $primary-dark !important;
  padding: 2rem 0 0;
}

.ftrList {
  // width: 10rem;
  p {
    font-size: 1rem;
    font-weight: bold;
    margin: 24px 0 1rem;
    color: #ffffff !important;
  }
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0px;
    font-size: 0.9rem;
    li {
      padding: 0 0 0.5rem;
      a {
        display: block;
        color: #ffffff;
        font-weight: 400;
        text-decoration: none;
      }
    }
  }
}

@media only screen and (max-width: 900px) {
  .ftrList {
    width: 100%;
  }
  .secondaryAppBar {
    top: 56px !important;
    .toolBar {
      flex-direction: row-reverse !important;
      padding: 0 5px;
    }
  }
  .toolBar.mapMobile {
    padding: 0;
  }
  .search-box {
    width: 100%;
    margin: 0 20px;
  }
  .statusGroup,
  .saleLeaseGroup {
    // position: absolute;
    // right: 16px !important;
    padding: 0;
  }
}

@media only screen and (max-width: 768px) {
  h1 {
    font-size: 1.8rem;
  }
  .autocomplete-dropdown-container {
    width: auto;
    top: 48px;
    left: -60px;
  }
  .saleLeaseGroup {
    position: relative;
    left: 4px !important;
    padding-bottom: 4px;
  }
}

@media only screen and (max-width: 680px) {
  .sm-font {
    font-size: 0.45em;
  }
}

@media only screen and (max-width: 400px) {
  h1 {
    font-size: 1.6rem;
  }
}
